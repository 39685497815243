import React, { Component } from "react";
import Navigation from "../Navigation";
import axios from "axios";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import GVolumen from "./GV1";
import GV2 from "./VBar";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Lecturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [],
      fecha1: "",
      fecha2: "",
      hora: "",
      sitio: "",
      nombre: "",
      contador: 1
    };
  }

  fecha1(event) {
    this.setState({ fecha1: event.target.value });
  }
  fecha2(event) {
    this.setState({ fecha2: event.target.value });
  }
  hora(event) {
    this.setState({ hora: event.target.value });
  }
  async cargarlecturas() {
    const res = await instance.get(
      "https://api.instrutronica-iot.com/api/la/volumen/fechas/" +
        this.state.sitio +
        "/" +
        this.state.fecha1 +
        "/" +
        this.state.fecha2 +
        "/" +
        this.state.hora
    );
    let lec = [];
    if (res.data.length > 1) {
      for (var i = 0; i < res.data.length; i++) {
        lec[i] = {
          fechalectura: res.data[i].fechalectura,
          volumen: res.data[i].volumen2
        };
      }
    }
    this.setState({ lecturas: lec });
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    this.setState({ sitio: sit[5] });

    var res = await instance.get(
      "https://api.instrutronica-iot.com/api/la/sitios/" + sit[5]
    );
    this.setState({ nombre: res.data[0].nombre });

    this.interval = setInterval(async () => {
      if (localStorage.getItem("the_main_app_lir1") == null) {
        this.props.history.push("/");
      }
    }, 1000);
  }

  render() {
    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="volumen" />
          <section className="widget-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 page-title">
                  <h2>{this.state.nombre}</h2>
                </div>
              </div>
              <div className="content-area">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3>Revisa por fechas las lecturas</h3>
                    <form>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <input
                            id="fecha1"
                            onChange={this.fecha1.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Inicial"
                            aria-label="Fecha Inicial"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            id="fecha2"
                            onChange={this.fecha2.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Final"
                            aria-label="Fecha fianl"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            id="hora"
                            onChange={this.hora.bind(this)}
                            type="number"
                            min="0"
                            max="23"
                            className="form-control"
                            placeholder="Hora de 0 a 23"
                            aria-label="Hora de 0 a 23"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <button
                            className="btn  btn-primary"
                            type="button"
                            onClick={this.cargarlecturas.bind(this)}
                          >
                            Cargar Lecturas
                          </button>
                          <CSVLink
                            className="btn btn-info"
                            data={this.state.lecturas}
                            asyncOnClick={true}
                            filename={
                              "" +
                              this.state.nombre +
                              "-" +
                              this.state.fecha1 +
                              "-" +
                              this.state.fecha2 +
                              "-reporte de volumen.csv"
                            }
                          >
                            Descargar CSV
                          </CSVLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="about-sec-2">
                  <div className="row">
                    <GV2
                      lecturas={this.state.lecturas}
                      contador={this.state.contador}
                    />
                    <GVolumen
                      lecturas={this.state.lecturas}
                      contador={this.state.contador}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Lecturas);
