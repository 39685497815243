import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import iconbat from '../../images/icon-2.png';
export class GVolumen extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);
    this.state = {
      chartOptions: {
        chart:{zoomType:"xy",type:"spline"},
        credits:{enabled:!1},
        title:{text: 'Volumen m3' },
        subtitle:{text:"Seleccione una sección para realizar zoom"},
        xAxis:{type:"datetime",labels:{overflow:"justify"}},
        yAxis:{
          title:{text:" "},
          minorGridLineWidth:0.8,
          gridLineWidth:1,
          alternateGridColor:null},
        tooltip:{
            headerFormat:"<b>{series.name}</b><br>",
            pointFormat:"{point.x:%e. %b}: <b>{point.y:.2f}</b>"},
        plotOptions:{spline:{lineWidth:1.5,
        states:{hover:{lineWidth:2}},
        marker:{enabled:!1}}},
        series:[
              {name:"Volumen",
              data:null,
              turboThreshold:0}
            ],
        navigation:{menuItemStyle:{fontSize:"10px"}},
        exporting:{
              filename: 'Volumen 33',
            sourceWidth:1920,sourceHeight:1080,chartOptions:{subtitle:null},
        }
      }, contador:0
    };
  }

  afterChartCreated(chart) {
    this.internalChart = chart;    
    const lecturas = this.props.lecturas;    
    const r = lecturas.map(function (lec) {
      var fd=lec.fechalectura.split("T");
      var di=fd[0].split("-");
      var di2=fd[1].split(":");
      var seg=di2[2].split(".");      
      return [Date.UTC(di[0],(di[1]-1),di[2],(di2[0]),di2[1],seg[0]),lec.volumen2];
      
    });     
    this.setState({
      chartOptions: {
        series: [
          { data: r}
        ]
      }
    });
    this.setState({contador:this.props.lecturas.length});
    
    this.forceUpdate();
  }

  componentDidUpdate() { const lecturas = this.props.lecturas;
    if(lecturas.length>1){ 
    var nnn=[];
    for(var i=0;i<lecturas.length;i++){
      var fd=lecturas[i].fechalectura.split("T");
      var di=fd[0].split("-");
      var di2=fd[1].split(":");
      var seg=di2[2].split(".");         
      nnn[i]=([Date.UTC(di[0],(di[1]-1),di[2],(di2[0]),di2[1],seg[0]),lecturas[i].volumen2]);
    } 
    if(this.state.contador!==nnn.length){
      this.setState({contador:nnn.length},()=>{
        this.setState({
          chartOptions: {
            series: [
              { data: nnn}
            ]
          }
        });  
    
    this.internalChart.reflow();
      });
    }
          
    }
     
  }
  
    render() {
      
      
        return (
            <div className="col-lg-6 col-md-6">
            <div className="content-stats">
                <div className="title-sec">
                    <h3> <img src={iconbat} alt="Water Icon"/> Volumen m<sup>3</sup></h3>
                </div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                    callback={this.afterChartCreated}
                    />
            </div>
        </div>
        )
    }
}

export default GVolumen;
